import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUser } from '../../contexts/CurrentUser.context';
import LogoutButton from './LogoutButton';
import { Link } from 'react-router-dom';

export default function AuthenticationButton() {
  const {
    isAuthenticated,
    user,
  } = useAuth0(); // 👈 1
  const {loaded, currentUser} = useCurrentUser();
  if (isAuthenticated) { // 👈 2
    const { picture, givenName } = user;
    console.log(currentUser)
    return (
      <>
        <div className="px-2">
          <Link to={`/users/${currentUser?.userid}`} className='no-link'>
            <div className='d-flex align-items-center text-white'>
              {loaded && currentUser.firstname ? currentUser.firstname : null} {loaded && currentUser ? currentUser.lastname : null}
            </div>
          </Link>
        </div>
        <div className="p-end-2">
          {loaded ? <Link to={`/users/${currentUser?.userid}`}>
            <img src={picture} alt={givenName} className="rounded" height="30px" />
          </Link>: null}
        </div>
      </>
    );
  }
}
