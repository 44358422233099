import { createContext,  useState,  useCallback,  useMemo, useContext, useEffect } from 'react';
import useUsers from '../api/users';

export const CurrentUserContext = createContext();

export const useCurrentUser = () => useContext(CurrentUserContext);

export const CurrentUserProvider = ({
  children
}) => {

  const [ currentUser, setCurrentUser ] = useState({});
  const [ loaded, setLoaded ] = useState(false);
  const userApi = useUsers();

  const getCurrentUser = useCallback(async () =>{
    try{
      const cu = await userApi.login();
      setCurrentUser(cu.data);
      setLoaded(true);
    }catch(error){
      setCurrentUser(null);
    }
  }, []);

  useEffect(() => {
    getCurrentUser();
  }, [loaded]);
  

  return <CurrentUserContext.Provider value={{currentUser, loaded, getCurrentUser}}>
    {children}
  </CurrentUserContext.Provider>;
};