import {
  useAuth0,
} from '@auth0/auth0-react';
import axios from 'axios';
import {
  useCallback,
} from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/categories`;

const useCategories = () => {
  const {
    getAccessTokenSilently,
  } = useAuth0();

  const getAll = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${baseUrl}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
 
    return response.data;
  }, [getAccessTokenSilently]);

  const getById = useCallback(async ({walletId, categoryId}) => {
    const token = await getAccessTokenSilently();

    const response = await axios.get(`${baseUrl}/${categoryId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        walletId: walletId,
      }
    });
 
    return response.data;
  }, [getAccessTokenSilently]);

  return {
    getAll,
    getById
  };
};

export default useCategories;