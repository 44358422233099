import { useCallback, useState } from "react";
import CardScanner from "../scan/CardScanner";

export default function ScanModal({onSuccess, show, onClose}){

  const modalStyle = {
    display: show ? 'block' : 'none',
    // ... other styles
  };

  const handleSuccess = useCallback(({walletId}) => {
    onSuccess({walletId})
  })

  if(!show){
    return <></>
  }

  return <>
    <div style={modalStyle} className={`modal-overlay`}>
      <div className="modal-content">
        <span className="modal-close" onClick={onClose}>&times;</span>
        <h1>Scan de kaart!</h1>
        <CardScanner onSuccess={handleSuccess}/>
      </div>
    </div>
  </>
}