import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import { useTheme } from '../../contexts/Theme.context';
import { useCallback } from 'react';

export default function Transaction({clickTransaction, id, amount, timestamp, firstname, lastname, cardnr, orderlines}){

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric'};
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (dateString) => {
    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric'};
    return new Date(dateString).toLocaleTimeString();
  };

  const {theme, oppositeTheme} = useTheme();

  const handleClick = useCallback(() => {
    clickTransaction(id)
  })

  return <>

    <tr key={id} className='clickable' data-cy="transaction" onClick={handleClick}>
      <td className={`text-${oppositeTheme}`}>
        {formatDate(timestamp)}
      </td>
      <td className={`text-${oppositeTheme} d-none d-lg-table-cell`}>
        {formatTime(timestamp)}
      </td>
      {firstname ? <td className={`text-${oppositeTheme}`} data-cy="transaction-name">{firstname} {lastname}</td> : null }
      <td className={amount > 0 ? 'text-success' : 'text-danger'}>
        {amount < 0 ? '-' : null}€{Math.abs(amount).toFixed(2)}
      </td>
    </tr>
    
    
  </>;
}