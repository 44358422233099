import {
  useAuth0,
} from '@auth0/auth0-react';
import axios from 'axios';
import {
  useCallback,
} from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/companies`;

const useCompanies = () => {
  const {
    getAccessTokenSilently,
  } = useAuth0();

  const getAll = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${baseUrl}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
 
    return response.data;
  }, [getAccessTokenSilently]);

  const getById = useCallback(async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${baseUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response);
    return response.data;
  },[getAccessTokenSilently]);

  const create = useCallback(async (name, street, number, postalcode, city, country) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: baseUrl,
      data: {name, street, number, postalcode, city, country},
    });

    console.log(response.data);
    return response.data;
  }, [getAccessTokenSilently]);

  const deleteComp = useCallback(async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios.delete(`${baseUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response);
  },[getAccessTokenSilently]);

  return {
    getAll,
    getById,
    create,
    deleteComp
  };
};

export default useCompanies;