export default function Error({ error }) {
  if (error) {
    return (
      <div className="alert alert-danger mt-4" data-cy="error-component">
        <h4 className="alert-heading">{error.err ? error.err : 'An error occured'}</h4>
        {error.message || JSON.stringify(error)}
      </div>
    );
  }

  return null;
}