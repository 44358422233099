import React from "react";
import { QrReader } from "react-qr-reader";
import { BsCheck, BsX } from "react-icons/bs";

export default function CardScanner({onSuccess}) {
  const handleScan = (result, error) => {
    if (!!result) {
      const [compId, walletId] = result.text.split("/");
      console.log(`Companie: ${compId}`)
      console.log(`Wallet: ${walletId}`)
      onSuccess({ compId, walletId });
    }
  };

  return (
    <QrReader
      onResult={handleScan}
      style={{ width: "100%" }}
      constraints={{ facingMode: "environment" }}
    />
  );
}

