import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Error from '../errors/Error';
import LoginButton from './LoginButton';
import useUsers from '../../api/users';

export default function AuthLanding() {
  const { error, isAuthenticated, isLoading } = useAuth0();
  const usersAPI = useUsers();
  const navigate = useNavigate();

  if (error) {
    <div className="container">
      <div className="row">
        <div className="col">
          <h1>Login failed</h1>
          <p>
            Sorry, we were unable to sign you in, the error below might be useful.
          </p>
          <Error error={error} />
          <LoginButton />
        </div>
      </div>
    </div>;
  }

  const registerUser = useCallback(async (id) => {
    console.log('making api call');
    try{
      const user = await usersAPI.create(id);
      console.log(`user: ${JSON.stringify(user)}`);
      if(user && user.firstname){
        navigate(`/users/${user.userid}`, {replace: true});
      }else{
        navigate(`/users/${user.userid}/update`, {replace: true});
      }
    }catch(error){
      console.log(error);
    }
  });

  if (!isLoading && isAuthenticated) {
    registerUser();
  }

  if (!isLoading && !isAuthenticated) {
    return (
      <div className="container h-100">
        <div className='d-flex justify-content-center align-items-center'>
          <div>
            <h1>Login required</h1>
            <p>You need to login to access this page.</p>
            <LoginButton/>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1>Signing in</h1>
          <p>
            Please wait while we sign you in!
          </p>
        </div>
      </div>
    </div>
  );
}
