import { memo, useCallback } from 'react';
import {
  IoMoonSharp,
  IoSunny
} from 'react-icons/io5';
import logoFull from '../images/logo_color.png';
import { Link } from 'react-router-dom';
import { useTheme, themes, useThemeColors } from '../contexts/Theme.context';
import LoginButton from './authentication/LoginButton';
import AuthenticationButton from './authentication/AuthenticationButton';
import { useCurrentUser } from '../contexts/CurrentUser.context';

const NavItem = ({ label, to }) => {
  const { oppositeTheme } = useThemeColors();

  return (
    <li className="nav-item">
      <Link
        to={to}
        className={'nav-link active text-white'}
      >
        {label}
      </Link>
    </li>
  );
};

export default memo(function Navbar() {
  const { loaded, currentUser} = useCurrentUser();

  const { theme, oppositeTheme, toggleTheme } = useTheme();

  return (<>

    <nav class="navbar" style={{backgroundColor: '#007bb4'}}>
      <div className='d-flex justify-content-lg-between align-items-center w-100'>
        <Link class="navbar-brand ms-3" to="/">
          <img src={logoFull} width="30" height="30" class="d-inline-block align-top" alt=""/>
          <span className='ms-2 text-white navbar-text'>TapWallet</span>
        </Link>
      
        <div className='d-flex me-2'>
        <AuthenticationButton className="d-flex"/> {/* 👈*/}
        </div>
      </div>
    </nav>

        
          
        {/* <button className={'btn'} type="button" onClick={toggleTheme} >
          {
            theme === themes.dark ? <IoMoonSharp /> : <IoSunny />
          }
        </button>  */}
  </>
  );
});