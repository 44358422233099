import { Routes, Route } from 'react-router-dom';
import CompaniesList from './components/companies/CompaniesList';
import User from './components/users/User';
import NotFound from './components/errors/NotFound';
import './App.css';
import CompanyForm from './components/companies/CompanyForm';
import Navbar from './components/Navbar';
import CardDetail from './components/cards/CardDetail';
import TopUpList from './components/cards/TopUpList';
import HomePage from './components/HomePage';
import CompanyDetail from './components/companies/CompanyDetail';
import WalletDetail from './components/wallets/WalletDetail';
import UserList from './components/users/UserList';
import { useTheme } from './contexts/Theme.context';
import RequireAuth from './components/authentication/RequireAuth';
import AuthLanding from './components/authentication/AuthLanding';
import UserForm from './components/users/UserForm';
import CardForm from './components/cards/CardForm';
import WalletForm from './components/wallets/WalletForm';
import { useState } from 'react';
import Kassa from './components/Kassa';
import WalletJoin from './components/wallets/WalletJoin';
import WalletTransactions from './components/wallets/WalletTransactions';

function App() {

  const { theme, oppositeTheme } = useTheme();
  const [showNav, setShowNav] = useState(true);


  return <>
    <div className={`app-container bg-${theme} text-${oppositeTheme}`}>
      <Navbar />

      <div className='app h-100'>
        <Routes>
          <Route index element={<HomePage funcNav={setShowNav}/>} />
          {/* <Route path="companies" >
            <Route index element={<RequireAuth><CompaniesList /></RequireAuth>} />
            <Route path="add" element={<CompanyForm />} />
            <Route path=":compId">
              <Route index element={<CompanyDetail />} />
              <Route path="wallets">
                <Route path="add" element={<RequireAuth><WalletForm /></RequireAuth>} />
                <Route path=":walletId">
                  <Route index element={<RequireAuth><WalletJoin /></RequireAuth>} />
                  <Route path="dashboard" element={<RequireAuth><WalletDetail /></RequireAuth>} />
                </Route> 
              </Route>
            </Route>
          </Route> */}
          <Route path='users'>
            {/* <Route index element={<RequireAuth><UserList /></RequireAuth>} /> */}
            <Route path=":id">
              <Route index element={<User />} />
              <Route path="update" element={<UserForm />} />
              <Route path="cards">
                <Route index element={<User />} />
                <Route path="add" element={<CardForm />} />
                <Route path=":cardnr">
                  <Route index element={<CardDetail />} />
                  <Route path="topup" element={<TopUpList />} />
                  {/*<Route path="register" element={<RequireAuth><Kassa /></RequireAuth>} />*/}
                </Route> 
                
              </Route>
            </Route>
          </Route>
          <Route path="/login" element={<AuthLanding />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  </>;
}

export default App;
