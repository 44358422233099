import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useUsers from '../../api/users';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../contexts/CurrentUser.context';

export default function UserForm(){

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [error, setError] = useState(null);
  const usersAPI = useUsers();
  const { id } = useParams();
  const navigate = useNavigate();
  const {getCurrentUser} = useCurrentUser();
  const [ user, setUser ] = useState(null);

  const validationRules = {
    firstname: {
      required: 'This is required',
    },
    lastname:{
      required: 'This is required',
    }
  };

  const onSaveUser = useCallback(async (e) => {
    const {firstname, lastname, street, number, postalcode, city, country} = e;
    try{
      await usersAPI.update(id, {firstname, lastname, street, number, postalcode, city, country});
      await getCurrentUser();
      navigate(`/users/${id}`, {replace: true});
    }catch(error){
      setError(error);
    }
    reset();
  }, [navigate]);
  
  const fetchUser = useCallback(async () => {
    const u = await usersAPI.getById(id);
    setUser(u);
  }, []);

  useEffect(() => {
    fetchUser();
  }, []);

  return <>
    <div className="container pb-4">
      <h3>Extra information</h3>
      <form onSubmit={handleSubmit(onSaveUser)} className="mb-3">
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">Firstname</label>
          <input
            {...register('firstname', validationRules.firstname)}
            defaultValue={user? user.firstname : ''}
            id="firstName"
            type="text"
            className="form-control"
            placeholder="Firstname"
          />
          {errors.firstname && <p className="form-text text-danger">{errors.firstname.message}</p> }
        </div>
        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">Lastname</label>
          <input
            {...register('lastname', validationRules.lastname)}
            defaultValue={user? user.lastname : ''}
            id="lastName"
            type="text"
            className="form-control"
            placeholder="Lastname"
          />
          {errors.lastname && <p className="form-text text-danger">{errors.lastname.message}</p> }
        </div>
        <input type="submit" className="btn btn-primary" value="Submit"/>
      </form>
    </div>
  </>;
}