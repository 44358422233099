export default function NotFound() {
  return (
    <div className="container mb-4 text-center">
      <h1>404</h1>
      <h1>Pagina niet gevonden</h1>
      <p>
          Er is geen pagina gevonden voor deze url.
      </p>
    </div>
  );
}