import { useCallback, useEffect, useState } from 'react';
import useCards, * as cardsAPI from '../../api/cards';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';


export default function TopUpButton({amount}){
  const { cardnr } = useParams();
  const navigate = useNavigate();
  const cardsAPI = useCards();

  const handleTopUp = useCallback(async () => {
    try {
      const response = await cardsAPI.createTransaction({amount, cardnr});
      if(!response.msg){
        navigate('..?success=true', {replace: true});
      }else{
        navigate(`..?success=false&msg=${response.msg}`, {replace: true});
      }
    } catch (error) {
      throw new Error('mission failed');
    }
  });

  return <>
    <div className="col-12 col-md-6 p-2">
      <button className="btn btn-lg btn-primary w-100 h-100" onClick={handleTopUp}>€{amount}</button>
    </div>
    
  </>;
}