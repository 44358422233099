import TopUpButton from './TopUpButton';
import { useLocation, useSearchParams } from 'react-router-dom';


export default function TopUpList(props){

  let {state} = useLocation();

  console.log(state);

  const topupOptions = [5,10,20, 50];
  return <> 
    <div className="container mt-4">
      <h2>Top up! </h2>
      <p>Choose an amount to top up your card!</p>
      <div className="row">
        {topupOptions.map(o => <TopUpButton key={o} amount={o} />)}
      </div>
    </div>
  </>;
}