import {
  useAuth0,
} from '@auth0/auth0-react';
import axios from 'axios';
import {
  useCallback,
} from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/transactions`;

const useTransaction = () => {
  const {
    getAccessTokenSilently,
  } = useAuth0();


  const getById = useCallback(async ({transactionId}) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${baseUrl}/${transactionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response.data);
    return response.data;
  },[getAccessTokenSilently]);

  return {
    getById,
  };
};

export default useTransaction;