import Transaction from './Transaction';
import { useTheme } from '../../contexts/Theme.context';
import { useCallback } from 'react';

export default function TransactionList({transactions, clickTransaction}){

  const { oppositeTheme } = useTheme();

  const handleClick = useCallback((id) => {
    clickTransaction(id)
  })

  return <>
    <table className="table table-responsive table-sm table-striped">
      <thead>
        <tr className={'m-4'}>
          <th className={`text-${oppositeTheme}`}>Datum</th>
          <th className={`text-${oppositeTheme} d-none d-lg-table-cell`} >Tijd</th>
          {transactions.length > 0 && transactions[0].firstname ? <th className={`text-${oppositeTheme}`} >Gebruiker</th> : null }
          <th className={`text-${oppositeTheme}`}>Bedrag</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(t => (
          <Transaction clickTransaction={handleClick} key={t.id} id={t.id} firstname={t.firstname} lastname={t.lastname} amount={t.amount} timestamp={t.timestamp} orderlines={t.orderlines} />
        ))} 
      </tbody>
    </table>
  </>;
}