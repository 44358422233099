import { useAuth0 } from '@auth0/auth0-react';
import { BsBoxArrowRight } from 'react-icons/bs';


function LogoutButton() {
  const { logout } = useAuth0();
  return (
    <BsBoxArrowRight 
      color='#C84758'
      className='clickable'
      size="30px"
      onClick={() => logout({
        returnTo: window.location.origin,
      })}>
      
    </BsBoxArrowRight>
  );
}

export default LogoutButton;
