import {
  useAuth0,
} from '@auth0/auth0-react';
import axios from 'axios';
import {
  useCallback,
} from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const useWallets = () => {

  const {
    getAccessTokenSilently,
  } = useAuth0();

  const getById = useCallback(async ({compId, walletId}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/companies/${compId}/wallets/${walletId}`, 
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
 
    return response.data;
  }, [getAccessTokenSilently]);

  const getUsersOfWallet = useCallback(async ({walletId, searchTerm}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/wallets/${walletId}/users`,
      params:{
        searchTerm
      }, 
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
 
    return response.data;
  });

  const getTransactionsOfWallet = useCallback(async ({compId, walletId, month, year}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/companies/${compId}/wallets/${walletId}/transactions`, 
      params: {
        'month': month,
        'year': year
      },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
 
    return response.data;
  });

  const getStockOfWallet = useCallback(async ({walletId, limit}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/wallets/${walletId}/products`, 
      params: {
        'limit': limit
      },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
 
    return response.data;
  });

  const updateStockOfWallet = useCallback(async ({walletId, productId, quantity, price}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'PUT',
      url: `${baseUrl}/wallets/${walletId}/products/${productId}`, 
      params: {
        'quantity': quantity,
        "price": price
      },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    return response.data
  });

  const getAnalytics = useCallback(async ({walletId, month, year}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/wallets/${walletId}/analytics`, 
      params: {
        'month': month,
        "year": year
      },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    return response.data
  });

  const createWallet = useCallback(async (compId, {name, description}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'POST',
      url: `${baseUrl}/companies/${compId}/wallets`,
      data: {name, description},
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    console.log(response);
    return response.data;
  });

  const deleteWallet = useCallback(async ({compId, walletId}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'DELETE',
      url : `${baseUrl}/companies/${compId}/wallets/${walletId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
  });

  return {
    createWallet,
    getById,
    getStockOfWallet,
    getUsersOfWallet,
    getTransactionsOfWallet,
    getAnalytics,
    deleteWallet,
    updateStockOfWallet
  };

};

export default useWallets;