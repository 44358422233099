import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import UserList from '../users/UserList';
import useUsers from '../../api/users';
import useCards from '../../api/cards';
import { useCurrentUser } from '../../contexts/CurrentUser.context';
import Error from '../errors/Error';


export default function CardForm({onSaveCard}) {

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { compId, walletId } = useParams();
  const navigate = useNavigate();
  const [ error, setError ] = useState();

  const usersAPI = useUsers();
  const cardsApi = useCards();
  const {loaded, currentUser} = useCurrentUser();

  const onSubmit = useCallback(async (data) => {
    try{
      let { cardnr } = data;
      if(!cardnr){
        cardnr = (Math.floor(Math.random() * 9) + 1).toString();
        for(let i = 0; i < 7; i ++){
          cardnr += Math.floor(Math.random() * 10).toString();
        }
      }
      const response = await cardsApi.createCard({compId, walletId, cardnr});
      if(response.err){
        setError(response);
      }else{
        navigate(`/users/${currentUser.userid}/cards/${response.id}`);
      }
    }catch(error){
      setError(error);
    }
    reset();
  });

  return <>
    <div className='container'>
      <button className="btn btn-primary mb-3 w-100" onClick={onSubmit}>
        Krijg jouw kaart nu!
      </button>
      <Error error={error} />
    </div>
  </>;
}